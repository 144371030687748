import React from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";
import useBreakpoints from "../../breakpoints";

import People from "../../images/people.svg";
import Saving from "../../images/saving.svg";
import Mobile from "../../images/mobile.svg";
import Analysis from "../../images/analysis.svg";

import Button from "../../components/Button";

const Service = () => {
  const lgUp = useBreakpoints({ breakpoint: "lg", dir: "up" });
  return (
    <IonPage>
      <IonContent>
        {lgUp ? (
          <section className="ion-padding">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div className="ion-text-center">
                    <h3>
                      良いWebサイト。それはお客様の魅力がグッと伝わるサイト。
                    </h3>
                  </div>
                  <div className="ion-padding-vertical" />
                  <p>
                    私たちは良いWebサイトを作成するためにはお客様との綿密なコミュニケーションが必要だと考えています。
                    制作を始める前に対面でお客様との打ち合わせをしてお客様の思い描くイメージを具現化いたします。
                    サイトの構成からサイト内で使用する写真や画像、キャッチコピーまで全てにおいて意見交換を繰り返すことでお客様の思い通りのWebサイトをご提供いたします。
                  </p>
                  <p>
                    私たちはWebサイトを作って終わりとすることはありません。
                    {/* Webサイトの更新・追加を可能とするシステムを用いて私たちはリリース後のお客様をサポートいたします。 */}
                    リリース後もお困りの際は気軽にご相談していただけるような関係づくりを大切にしています。
                  </p>
                </IonCol>
                <IonCol className="ion-text-center">
                  <img src={People} alt="つながりを象徴する人々の画像" />
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        ) : (
          <section>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div
                    className="ion-text-center"
                    style={{
                      background: `linear-gradient(to bottom,rgba(254,235,238,0) , white),url(${People})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                    }}
                  >
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding">
                      <h3>
                        良いWebサイト。それはお客様の魅力がグッと伝わるサイト。
                      </h3>
                      <div className="ion-padding-vertical" />
                    </div>
                  </div>
                  <div className="ion-padding">
                  <p>
                    私たちは良いWebサイトを作成するためにはお客様との綿密なコミュニケーションが必要だと考えています。
                    制作を始める前に対面でお客様との打ち合わせをしてお客様の思い描くイメージを具現化いたします。
                    サイトの構成からサイト内で使用する写真や画像、キャッチコピーまで全てにおいて意見交換を繰り返すことでお客様の思い通りのWebサイトをご提供いたします。
                  </p>
                  <p>
                    私たちはWebサイトを作って終わりとすることはありません。
                    {/* Webサイトの更新・追加を可能とするシステムを用いて私たちはリリース後のお客様をサポートいたします。 */}
                    リリース後もお困りの際は気軽にご相談していただけるような関係づくりを大切にしています。
                  </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        )}

        {lgUp ? <div className="ion-padding-vertical" /> : null}
        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}

        {lgUp ? (
          <section
            className="ion-padding"
            style={{ backgroundColor: "#ffebee" }}
          >
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                  <img
                    src={Mobile}
                    alt="大きなスマートフォンにもたれかかる人の画像"
                  />
                </IonCol>
                <IonCol className="ion-text-start">
                  <div className="ion-text-center">
                    <h3>
                      お客様とつながる第一歩。それは誰でも見ることができるサイト。
                    </h3>
                  </div>
                  <div className="ion-padding-vertical" />
                  <p>
                    スマートフォンやノートパソコン、タブレット端末などWebサイトに訪れるデバイスは実に多様です。
                    私たちはいろいろなデバイスでも見やすく使いやすいレスポンシブなWebサイト制作にも対応します。
                  </p>
                  {/* <p>
                    またサイトを多くの人に見られるためには検索結果で上位に表示されることが必要不可欠です。
                    私たちはリリース前にSEOなどの項目をチェックする過程を設けています。
                    私たちがあらかじめ設定した基準を超えた高クオリティーのサイトのみをご提供いたします。
                  </p> */}
                  <p>
                    また表示速度などの測定を行い、本当に使いやすく多くの人が見やすいのかを定量的に評価し、一定の基準を満たすまでパフォーマンス改善を行うことで、品質の維持をはかります。
                  </p>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        ) : (
          <section style={{ backgroundColor: "#ffebee" }}>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div
                    className="ion-text-center"
                    style={{
                      background: `linear-gradient(to bottom,rgba(254,235,238,0)  , #ffebee),url(${Mobile})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                    }}
                  >
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding">
                      <h3>
                        お客様とつながる第一歩。それは誰でも見ることができるサイト。
                      </h3>
                      <div className="ion-padding-vertical" />
                    </div>
                  </div>
                  <div className="ion-padding">
                    <p>
                      スマートフォンやノートパソコン、タブレット端末などWebサイトに訪れるデバイスは実に多様です。
                      私たちはいろいろなデバイスでも見やすく使いやすいレスポンシブなWebサイト制作にも対応します。
                    </p>
                    {/* <p>
                      またサイトを多くの人に見られるためには検索結果で上位に表示されることが必要不可欠です。
                      私たちはリリース前にSEOなどの項目をチェックする過程を設けています。
                      私たちがあらかじめ設定した基準を超えた高クオリティーのサイトのみをご提供いたします。
                    </p> */}
                    <p>
                      また表示速度などの測定を行い、本当に使いやすく多くの人が見やすいのかを定量的に評価し、一定の基準を満たすまでパフォーマンス改善を行うことで、品質の維持をはかります。
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        )}

        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}
        {lgUp ? <div className="ion-padding-vertical" /> : null}

        {lgUp ? (
          <section className="ion-padding">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div className="ion-text-center">
                    {/* <h3>良いWebサイトをお手軽に。それならNewPle。</h3> */}
                    <h3>今ある固定費を抑えられる。</h3>
                  </div>
                  <div className="ion-padding-vertical" />
                  {/* <p>
                    私たちの提供するWebサイトに載せる写真などの画像や文章はあらかじめお客様に決めていただいています。
                    私たちは写真撮影や画像の選択、文章作成に力を注がずに、Webサイト制作に注力することで制作にかかる費用や時間を抑えることで、高品質を保ちながら安価でスピーディーな制作をしております。
                  </p> */}
                  <p>
                    Webサイトには維持費や更新に費用がかかるものもあります。NewPleはエンジニアではないお客様にも更新が容易になるような工夫を凝らしたコーディングを行い、お客様が更新できるWebサイトの制作も可能です。
                  </p>
                  <p>
                    また、サーバー費用なども条件やお客様にも一部ご協力いただける場合は無料でWebサイトを公開することも可能です。
                  </p>
                  <p>
                    月々のWebサイト維持費にお困りの方は気軽にご相談ください。
                  </p>
                </IonCol>
                <IonCol className="ion-text-center">
                  <img
                    src={Saving}
                    alt="大きな豚の貯金箱にお金を入れる人の画像"
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        ) : (
          <section className="ion-padding">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div
                    className="ion-text-center"
                    style={{
                      background: `linear-gradient(to bottom,rgba(254,235,238,0)  , white),url(${Saving})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                    }}
                  >
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding">
                      {/* <h3>良いWebサイトをお手軽に。それならNewPle。</h3> */}
                      <h3>今ある固定費を抑えられる。</h3>
                    </div>
                    <div className="ion-padding-vertical" />
                  </div>
                  <div className="ion-padding">
                      {/* <p>
                      私たちの提供するWebサイトに載せる写真などの画像や文章はあらかじめお客様に決めていただいています。
                      私たちは写真撮影や画像の選択、文章作成に力を注がずに、Webサイト制作に注力することで制作にかかる費用や時間を抑えることで、高品質を保ちながら安価でスピーディーな制作をしております。
                    </p> */}
                    <p>
                      Webサイトには維持費や更新に費用がかかるものもあります。NewPleはエンジニアではないお客様にも更新が容易になるような工夫を凝らしたコーディングを行い、お客様が更新できるWebサイトの制作も可能です。
                    </p>
                    <p>
                      また、サーバー費用なども条件やお客様にも一部ご協力いただける場合は無料でWebサイトを公開することも可能です。
                    </p>
                    <p>
                      月々のWebサイト維持費にお困りの方は気軽にご相談ください。
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        )}

        {lgUp ? <div className="ion-padding-vertical" /> : null}
        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}

        {lgUp ? (
          <section
            className="ion-padding"
            style={{ backgroundColor: "#ffebee" }}
          >
            <IonGrid>
              <IonRow className="ion-align-items-center ion-no-padding">
                <IonCol className="ion-text-center">
                  <img src={Analysis} alt="分析する人の画像" />
                </IonCol>
                <IonCol>
                  <h3 className="ion-text-center">
                    オプション機能でWebサイトをより便利にしませんか？
                  </h3>

                  {/* <IonCard>
                        <IonCardHeader>
                          <IonCardTitle>セットの基本料金</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>4ページ5万円</IonCardContent>
                      </IonCard> */}
                  <IonCard>
                    <IonCardHeader>
                          <IonCardTitle>CMSの導入</IonCardTitle>
                          {/* <IonCardSubtitle className="ion-text-right">
                            3万円
                          </IonCardSubtitle> */}
                    </IonCardHeader>
                    <IonCardContent>
                      CMSとはコンテンツ・マネジメント・システムのことです。
                      システムを導入することでプログラミング等の専門的な知識が必要なく時間や手間のコストをかけずにWebサイトの更新等を行うことができます。
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                          <IonCardTitle>メールフォームの実装</IonCardTitle>
                          {/* <IonCardSubtitle className="ion-text-right">
                            2万円
                          </IonCardSubtitle> */}
                    </IonCardHeader>
                    <IonCardContent>
                      Webサイト上にお問い合わせページを実装することができます。
                      お問い合わせページがあることでWebサイトを訪れたお客様から直接ご連絡をいただけるようになります。
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                          <IonCardTitle>SNSの埋め込み</IonCardTitle>
                          {/* <IonCardSubtitle className="ion-text-right">
                            1万円
                          </IonCardSubtitle> */}
                    </IonCardHeader>
                    <IonCardContent>
                      Webサイト上にtwitterのSNSアカウント等をのせることができます。
                      SNSの投稿をのせることでWebサイトを訪れたお客様はより多くの有益な情報を手に入れることができます。
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              {/* <IonRow className="ion-float-right ion-text-center">
                <IonCol>
                  <p>
                    制作の料金は内容によって変わります。まずはご相談ください。
                  </p>
                  <Button path="/contact" text="NewPleに問い合わせる" />
                </IonCol>
              </IonRow> */}
            </IonGrid>
          </section>
        ) : (
          <section style={{ backgroundColor: "#ffebee" }}>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                  <div
                    style={{
                      background: `linear-gradient(to bottom,rgba(254,235,238,0)  , #ffebee),url(${Analysis})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                    }}
                  >
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding">
                      <h3>オプション機能でWebサイトをより便利にしませんか？</h3>
                    </div>
                    <div className="ion-padding-vertical" />
                  </div>
                  {/* <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>セットの基本料金</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>4ページ5万円</IonCardContent>
                  </IonCard> */}
                  {/* <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>CMSの導入</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>3万円</IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>メールフォームの実装</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>2万円</IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>SNSの埋め込み</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>1万円</IonCardContent>
                  </IonCard> */}
                  <IonCard>
                    <IonCardHeader>
                          <IonCardTitle>CMSの導入</IonCardTitle>
                          {/* <IonCardSubtitle className="ion-text-right">
                            3万円
                          </IonCardSubtitle> */}
                    </IonCardHeader>
                    <IonCardContent>
                      CMSとはコンテンツ・マネジメント・システムのことです。
                      システムを導入することでプログラミング等の専門的な知識が必要なく時間や手間のコストをかけずにWebサイトの更新等を行うことができます。
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                          <IonCardTitle>メールフォームの実装</IonCardTitle>
                          {/* <IonCardSubtitle className="ion-text-right">
                            2万円
                          </IonCardSubtitle> */}
                    </IonCardHeader>
                    <IonCardContent>
                      Webサイト上にお問い合わせページを実装することができます。
                      お問い合わせページがあることでWebサイトを訪れたお客様から直接ご連絡をいただけるようになります。
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                          <IonCardTitle>SNSの埋め込み</IonCardTitle>
                          {/* <IonCardSubtitle className="ion-text-right">
                            1万円
                          </IonCardSubtitle> */}
                    </IonCardHeader>
                    <IonCardContent>
                      Webサイト上にtwitterのSNSアカウント等をのせることができます。
                      SNSの投稿をのせることでWebサイトを訪れたお客様はより多くの有益な情報を手に入れることができます。
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
              {/* <IonRow className="ion-float-right ion-text-center">
                <IonCol>
                  <p>
                    制作の料金目安です。内容によって料金は変動します。まずは気軽にお問い合わせください。
                  </p>
                  <Button path="/contact" text="NewPleに問い合わせる" />
                </IonCol>
              </IonRow> */}
            </IonGrid>
          </section>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Service;
