import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/react";
import React from "react";

import useBreakpoints from "../../breakpoints";

import pic404 from "../../images/NotFound.svg";

import Button from "../../components/Button";

const NotFound = () => {
  const lgUp = useBreakpoints({ breakpoint: "lg", dir: "up" });
  return (
    <IonPage>
      <IonContent>
        <section>
          <IonGrid>
            <IonRow>
              <IonCol className="ion-text-center">
                {lgUp ? (
                  <img
                    src={pic404}
                    alt="ページが見つかりませんでした"
                    style={{ height: "70vh" }}
                  />
                ) : (
                  <img src={pic404} alt="ページが見つかりませんでした" />
                )}
                <div className="ion-padding" />
                <h1>ページが見つかりませんでした</h1>
                <Button path="/home" text="トップページに戻る" />
              </IonCol>
            </IonRow>
          </IonGrid>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default NotFound;
