import React from "react";
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
} from "@ionic/react";
import { Route, Switch, Redirect } from "react-router-dom";

import Routing from "../../Routing";
import useBreakpoints from "../../breakpoints";

import NotFound from "../../pages/NotFound";

import Logo from "../../images/newple-logo-rect-rad-with-name-1.svg";

const MainTab = () => {
  const lgUp = useBreakpoints({ breakpoint: "lg", dir: "up" });
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Switch>
          <Redirect exact path="/" to="/home" />
          {Routing.map(({ path, component }, index) => {
            return (
              <Route
                path={path}
                component={component}
                exact={true}
                key={index}
              />
            );
          })}
          <Route component={NotFound} />
        </Switch>
      </IonRouterOutlet>
      {lgUp ? (
        <IonTabBar slot="top">
          {Routing.map(({ path, discription }, index) => {
            if (path === "/home") {
              return (
                <IonTabButton tab={path} href={path} key={index}>
                  <h1>
                    <img src={Logo} alt="NewPleのロゴ" />
                  </h1>
                </IonTabButton>
              );
            }
            return (
              <IonTabButton tab={path} href={path} key={index}>
                <IonLabel>
                  <h2>{discription}</h2>
                </IonLabel>
              </IonTabButton>
            );
          })}
        </IonTabBar>
      ) : (
        <IonTabBar slot="bottom">
          {Routing.map(({ path, discription, icon }, index) => {
            return (
              <IonTabButton tab={path} href={path} key={index}>
                <IonIcon icon={icon} />
                <IonLabel>{discription}</IonLabel>
              </IonTabButton>
            );
          })}
        </IonTabBar>
      )}
    </IonTabs>
  );
};

export default MainTab;
