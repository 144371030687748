import {
  informationCircleOutline,
  terminalOutline,
  chatbubbleEllipsesOutline,
} from "ionicons/icons";

import Logo from "./images/newple-logo-rad.svg";

import Home from "./pages/Home";
import About from "./pages/About";
import Service from "./pages/Service";
// import Works from "./pages/Works";
import Contact from "./pages/Contact";

const Routing = [
  {
    path: "/home",
    discription: "ホーム",
    icon: Logo,
    component: Home,
  },
  {
    path: "/about",
    discription: "NewPleについて",
    icon: informationCircleOutline,
    component: About,
  },
  {
    path: "/service",
    discription: "Web開発",
    icon: terminalOutline,
    component: Service,
  },
  // {
  //   path: "/works",
  //   discription: "制作実績",
  //   icon: codeSlashOutline,
  //   component: Works,
  // },
  {
    path: "/contact",
    discription: "お問い合わせ",
    icon: chatbubbleEllipsesOutline,
    component: Contact,
  },
];

export default Routing;
