import React from "react";
import { IonButton, IonIcon } from "@ionic/react";

import { chevronForwardOutline } from "ionicons/icons";

const Button = ({ path, text }) => {
  return (
    <IonButton href={path} fill="outline" style={{ textTransform: "none" }}>
      {text}
      <IonIcon icon={chevronForwardOutline} />
    </IonButton>
  );
};

export default Button;
