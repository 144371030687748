import React, { useState, useEffect } from "react";
import {
  IonContent,
  IonList,
  IonItem,
  IonInput,
  IonLabel,
  IonPage,
  IonTextarea,
  IonButton,
  IonChip,
  IonCheckbox,
  IonAlert,
} from "@ionic/react";

import useBreakpoints from "../../breakpoints";
// const { Webhook, MessageBuilder } = require("discord-webhook-node");
// const hook = new Webhook(
//   "https://discord.com/api/webhooks/864387329234042880/g7IGKXQ2Dr5K6QRw9_VJcYa_LAojnI35RzW1bChRpSq0a6SwhEY0ubiv2vC6rFK9kUOR"
// );

const Contact = () => {
  const smUp = useBreakpoints({ breakpoint: "sm", dir: "up" });

  const [input, setInput] = useState({
    isSelfEmployed: false,
    companyName: "",
    name: "",
    email: "",
    contents: "",
  });

  const [disableSubmit, setDisableSubmit] = useState(true);
  const [showAlert1, setShowAlert1] = useState(false);
  const [showAlert2, setShowAlert2] = useState(false);

  // const [isValidate, setIsValidate] = useState(true);

  // const validation = (i) => {
  //   const regex = /[^\s]+@[^\s]+/g;
  //   setIsValidate(regex.test(i));
  // };

  useEffect(() => {
    if (
      (input.isSelfEmployed || input.companyName) &&
      input.name &&
      input.email &&
      input.contents
    ) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [input]);

  return (
    <IonPage>
      <IonContent>
        <section className="ion-padding">
          <form
            style={{ width: `${smUp ? "60%" : "100%"}`, margin: "0 auto" }}
            action="contact"
            onSubmit={(e) => {
              e.preventDefault();
              const data = {
                name: encodeURIComponent(input.name),
                email: encodeURIComponent(input.email),
                contents: encodeURIComponent(input.contents),
                companyName: encodeURIComponent(input.companyName),
                isSelfEmployed: encodeURIComponent(input.isSelfEmployed),
              };
              const contents = (body) => {
                const { name, email, contents, companyName, isSelfEmployed } =
                  body;
                return `以下の内容で、ホームページよりお問い合わせを受けました。
                会社名: ${
                  decodeURIComponent(isSelfEmployed) === "true"
                    ? "個人のお客様"
                    : decodeURIComponent(companyName)
                }
                氏名: ${decodeURIComponent(name)}
                メールアドレス: ${decodeURIComponent(email)}
                お問い合わせ内容: ${decodeURIComponent(contents)}
                時間: ${new Date().toDateString()}
                `;
              };
              fetch(
                "https://discord.com/api/webhooks/864387329234042880/g7IGKXQ2Dr5K6QRw9_VJcYa_LAojnI35RzW1bChRpSq0a6SwhEY0ubiv2vC6rFK9kUOR",
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    content: contents(data),
                  }),
                }
              )
                .then((res) => {
                  if (res.ok) {
                    setShowAlert1(true);
                  } else {
                    setShowAlert2(true);
                  }
                })
                .catch((err) => setShowAlert2(true));
              // try {
              //   hook
              //     .send(contents(data))
              //     .then((res) => {
              //       console.log("res", res);
              //       if (res.status === 200) {
              //         setShowAlert1(true);
              //       } else {
              //         setShowAlert2(true);
              //       }
              //     })
              //     .catch((err) => {
              //       setShowAlert2(true);
              //       console.error(err);
              //     });
              // } catch (error) {
              //   console.error(error);
              // }
            }}
          >
            <IonList>
              <IonItem>
                <IonChip color="primary">optional</IonChip>
                <IonLabel>個人のお客様</IonLabel>
                <IonCheckbox
                  color="secondary"
                  name="isSelfEmployed"
                  onIonChange={(e) =>
                    setInput((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.checked,
                      };
                    })
                  }
                />
              </IonItem>
              <IonItem disabled={input.isSelfEmployed}>
                <IonLabel position="floating">会社名、お店の名前</IonLabel>
                <IonInput
                  value={input.companyName}
                  name="companyName"
                  onIonChange={(e) =>
                    setInput((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">氏名</IonLabel>
                <IonInput
                  value={input.name}
                  name="name"
                  onIonChange={(e) =>
                    setInput((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">メールアドレス</IonLabel>
                <IonInput
                  // onIonBlur={validation}
                  inputMode="email"
                  name="email"
                  value={input.email}
                  onIonChange={(e) => {
                    setInput((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.value,
                      };
                    });
                    // validation(e.target.value);
                  }}
                />
                {/* {isValidate ? null : (
                  <>
                    <IonIcon icon={warningOutline} slot="end" color="danger" />
                    <IonText color="danger">
                      <p>無効なメールアドレスです。</p>
                    </IonText>
                  </>
                )} */}
              </IonItem>
              <IonItem>
                <IonLabel position="floating">お問い合わせ内容</IonLabel>
                <IonTextarea
                  rows={8}
                  value={input.contents}
                  name="contents"
                  onIonChange={(e) =>
                    setInput((prev) => {
                      return {
                        ...prev,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </IonItem>
            </IonList>
            <IonButton
              // onClick={() => {
              //   setShowAlert1(true);
              // }}
              disabled={disableSubmit}
              type="submit"
              expand="block"
              shape="round"
              fill="outline"
            >
              送信する
            </IonButton>
            <IonAlert
              isOpen={showAlert1}
              onDidDismiss={() => setShowAlert1(false)}
              header={"お問い合わせありがとうございます。"}
              message={
                "私たちからの連絡をお待ちください。2~3日以内にお返事いたします。"
              }
              buttons={["OK"]}
            />
            <IonAlert
              isOpen={showAlert2}
              onDidDismiss={() => setShowAlert2(false)}
              header={"大変申し訳ございません。"}
              message={
                "問題が発生し、お問い合わせの送信に失敗しました。お手数ですがnewple.contact@gmail.comまでご連絡お願いいたします。"
              }
              buttons={["OK"]}
            />
          </form>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default Contact;
