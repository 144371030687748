import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/react";
import React from "react";
import useBreakpoints from "../../breakpoints";

import Connect from "../../images/connect.svg";
import Coding from "../../images/coding.svg";
import Contact from "../../images/contact.svg";

import Button from "../../components/Button";

const Home = () => {
  const smUp = useBreakpoints({ breakpoint: "sm", dir: "up" });
  const lgUp = useBreakpoints({ breakpoint: "lg", dir: "up" });
  return (
    <IonPage>
      <IonContent>
        <section className="ion-padding">
          <IonGrid>
            {smUp ? (
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <h3 className="ion-text-center">
                    多くの人に、{smUp ? null : <br />}多くのつながりを。
                  </h3>
                  <div className="ion-text-center">
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <Button path="/about" text="NewPleについて" />
                  </div>
                </IonCol>
                <IonCol className="ion-text-center">
                  <img
                    src={Connect}
                    alt="企業理念の画像"
                    width="80%"
                    height="80%"
                  />
                </IonCol>
              </IonRow>
            ) : (
              <IonRow className="ion-align-items-center">
                <IonCol size="12">
                  <h3 className="ion-text-center">
                    多くの人に、
                    <br />
                    多くのつながりを。
                  </h3>
                </IonCol>
                <IonCol size="12" className="ion-text-center">
                  <img src={Connect} alt="企業理念の画像" />
                  <div className="ion-text-end ion-padding">
                    <Button path="/about" text="NewPleについて" />
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </section>
        {lgUp ? <div className="ion-padding-vertical" /> : null}
        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}
        <section className="ion-padding" style={{ backgroundColor: "#ffebee" }}>
          <IonGrid className="ion-padding-bottom">
            {smUp ? (
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                  <img src={Coding} alt="Web開発の画像" />
                </IonCol>
                <IonCol>
                  <h3 className="ion-text-center">
                    つながりを広げる、Webサービスを作ります。
                  </h3>
                  <div className="ion-text-center">
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <Button path="/service" text="NewPleの事業" />
                  </div>
                </IonCol>
              </IonRow>
            ) : (
              <IonRow className="ion-align-items-center">
                <IonCol size="12">
                  <h3 className="ion-text-center">
                    つながるための、Webサイトを作ります。
                  </h3>
                </IonCol>
                <IonCol size="12" className="ion-text-center">
                  <img src={Coding} alt="Web開発の画像" />
                  <div className="ion-text-end ion-padding">
                    <Button path="/service" text="NewPleの事業" />
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </section>
        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}
        {lgUp ? <div className="ion-padding-vertical" /> : null}
        <section className="ion-padding">
          <IonGrid className="ion-padding-bottom">
            {smUp ? (
              <IonRow className="ion-align-items-center">
                <IonCol>
                  <h3 className="ion-text-center">なんでもご相談ください。</h3>
                  <div className="ion-text-center">
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <Button path="/contact" text="NewPleに問い合わせる" />
                  </div>
                </IonCol>
                <IonCol className="ion-text-center">
                  <img src={Contact} alt="Web開発の画像" />
                </IonCol>
              </IonRow>
            ) : (
              <IonRow className="ion-align-items-center">
                <IonCol size="12">
                  <h3 className="ion-text-center">なんでもご相談ください。</h3>
                </IonCol>
                <IonCol size="12" className="ion-text-center">
                  <img src={Contact} alt="Web開発の画像" />
                  <div className="ion-text-end ion-padding">
                    <Button path="/contact" text="NewPleに問い合わせる" />
                  </div>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </section>
      </IonContent>
    </IonPage>
  );
};

export default Home;
