import React from "react";
import {
  IonPage,
  IonItem,
  IonLabel,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
} from "@ionic/react";
import useBreakpoints from "../../breakpoints";

import Hello from "../../images/hello.svg";
import Thought from "../../images/thought.svg";

const About = () => {
  const smUp = useBreakpoints({ breakpoint: "sm", dir: "up" });
  const lgUp = useBreakpoints({ breakpoint: "lg", dir: "up" });

  const companyInfo = [
    { label: "社名", content: ["合同会社NewPle"] },
    { label: "設立", content: ["2020年7月13日"] },
    { label: "所在地", content: ["東京都世田谷区"] },
    { label: "事業内容", content: ["Webサイト制作", "アプリ開発"] },
    { label: "代表", content: ["新開崇弘", "高田楓我"] },
  ];

  return (
    <IonPage>
      <IonContent>
        {lgUp ? (
          <section className="ion-padding">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div className="ion-text-center">
                    <h3>NewPleとは？</h3>
                  </div>
                  <div className="ion-padding-vertical" />
                  {/* <p>
                    代表の2人は高校時代の同級生であり、大学3年生の時に新開崇弘の新から"New"、高田楓我の楓(maple)から"Ple"を取ってNewPleを設立しました。
                    私たちは自由な活動や経営をすることを目的として、会社形態として合同会社を選択しました。
                  </p> */}
                  <p>
                    私たちNewPleは大学生2人でIT技術を用いて「つながり」のためのWebサイト制作会社を営んでいます。
                  </p>
                  <p>
                    {/* 多くの方々に、お客様の情報を見やすく、使いやすいWebサイトを通して届けられるように尽力します。 */}
                    お客様の大切な「つながり」を守り、新しい「つながり」の手助けに尽力します。
                  </p>
                </IonCol>
                <IonCol className="ion-text-center">
                  <img src={Hello} alt="挨拶をしている人の画像" />
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        ) : (
          <section>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div
                    className="ion-text-center"
                    style={{
                      background: `linear-gradient(to bottom,rgba(254,235,238,0) , white),url(${Hello})`,
                      backgroundSize: "cover",
                      backgroundPosition: "top",
                    }}
                  >
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding">
                      <h3>NewPleとは？</h3>
                    </div>
                    <div className="ion-padding-vertical" />
                  </div>
                  <div className="ion-padding">
                    <div className="ion-padding"></div>
                    {/* <p>
                    代表の2人は高校時代の同級生であり、大学3年生の時に新開崇弘の新から"New"、高田楓我の楓(maple)から"Ple"を取ってNewPleを設立しました。
                    私たちは自由な活動や経営をすることを目的として、会社形態として合同会社を選択しました。
                  </p> */}
                    <p>
                      私たちNewPleは大学生2人でIT技術を用いて「つながり」のためのWebサイト制作会社を営んでいます。
                    </p>
                    <p>
                      {/* 多くの方々に、お客様の情報を見やすく、使いやすいWebサイトを通して届けられるように尽力します。 */}
                      お客様の大切な「つながり」を守り、新しい「つながり」の手助けに尽力します。
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        )}

        {lgUp ? <div className="ion-padding-vertical" /> : null}
        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}

        {lgUp ? (
          <section
            className="ion-padding"
            style={{ backgroundColor: "#ffebee" }}
          >
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                  <img src={Thought} alt="考える人の画像" />
                </IonCol>
                <IonCol className="ion-text-start">
                  <div className="ion-text-center">
                    <h3>NewPleの考え方</h3>
                  </div>
                  <div className="ion-padding">
                    {/* <p>
                      私たちは魂のこもったつながりを届けるべく活動しています。
                      私たちが大切にしている「つながり」とは単に点と点を結ぶ線のことを指しているわけではありません。
                    </p>
                    <p>
                      私たち・お客様・Webサイトを利用するお客様と密接に「つながる」ことでDNAのような密接な関係を目指しています。
                      お客様の望む未来へと導くためのWebサービスを具現化し提供していきます。
                    </p> */}
                    <p>
                      お客様の「つながり」のためには、正しくわかりやすくお客様の情報をお届けすることが大切だと考えています。
                    </p>
                    <p>
                      そして情報をわかりやすくお届けするためには使いやすく、見やすいWebサイトが必要不可欠だと考えています。
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        ) : (
          <section style={{ backgroundColor: "#ffebee" }}>
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-start">
                  <div
                    className="ion-text-center"
                    style={{
                      background: `linear-gradient(to bottom,rgba(254,235,238,0) , #ffebee),url(${Thought})`,
                      backgroundSize: "cover",
                      backgroundPosition: "left",
                    }}
                  >
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding-vertical" />
                    <div className="ion-padding">
                      <h3>NewPleの考え方</h3>
                    </div>
                    <div className="ion-padding-vertical" />
                  </div>
                  <div className="ion-padding">
                    {/* <p>
                      私たちは魂のこもったつながりを届けるべく活動しています。
                      私たちが大切にしている「つながり」とは単に点と点を結ぶ線のことを指しているわけではありません。
                    </p>
                    <p>
                      私たち・お客様・Webサイトを利用するお客様と密接に「つながる」ことでDNAのような密接な関係を目指しています。
                      お客様の望む未来へと導くためのWebサービスを具現化し提供していきます。
                    </p> */}
                    <p>
                      お客様の「つながり」のためには、正しくわかりやすくお客様の情報をお届けすることが大切だと考えています。
                    </p>
                    <p>
                      そして情報をわかりやすくお届けするためには使いやすく、見やすいWebサイトが必要不可欠だと考えています。
                    </p>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        )}

        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}
        {lgUp ? <div className="ion-padding-vertical" /> : null}

        {lgUp ? (
          <section className="ion-padding">
            <IonGrid>
              <div className="ion-text-center">
                <h3>NewPleの行動指針</h3>
                <p>
                  私たちは成長を続け私たちの考えの遂行のために制作・開発を行う上で次の6つの指針を設けています。
                  これらはお客様とWebサイトを利用するお客様のために順守いたします。
                </p>
                {/* <div className="ion-padding-vertical" /> */}
                <IonRow style={{ alignItems: "center" }}>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>学び続けていこう。</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <p>
                          常に進化し続ける技術が有用であるものならば、私たちは新しいものを取り入れることを迷いません。
                        </p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>能動的に行動しよう。</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <p>
                          「こうなったらいいな」などと理想を言うことは簡単です。私たちは常に改善するためのアイデアを考えます。
                        </p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>謙虚にいよう。</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <p>
                          私たちは素直に他人の意見を聞き、何が最善か考えます。
                          他人から学ぶことは非常に大きな価値があると考えています。
                        </p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
                <IonRow style={{ alignItems: "center" }}>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>
                          報酬以上の価値を追究しよう。
                        </IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        {/* <p>
                            私たちは制作物に見合わない報酬を求めることは絶対にしません。常に満足してもらう方法を考えつくします。
                          </p> */}
                        <p>常にいただく報酬以上の価値を追求します。</p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>誠実であろう。</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        {/* <p>
                            私たちは都合の良いことばかりを並べません。時には都合の悪いお話をするかもしれませんが、適切な情報をお伝えします。
                          </p> */}
                        <p>
                          私たちはお客様のため、Webサイトを訪れる方々の両方のために、NewPleにとって都合の悪い情報であろうと適切な情報をお伝えすることを約束します。
                        </p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>

                  <IonCol>
                    <IonCard>
                      <IonCardHeader>
                        <IonCardTitle>尽くす気持ちでいよう。</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <p>
                          仲間内だけでなく相手のことを一歩先まで考えます。貢献すると気分が良いだけではなく、大きなチャンスが巡ってくる。私たちはそう信じています。
                        </p>
                      </IonCardContent>
                    </IonCard>
                  </IonCol>
                </IonRow>
              </div>
            </IonGrid>
          </section>
        ) : (
          <section className="ion-padding">
            <IonGrid>
              <IonRow className="ion-align-items-center">
                <IonCol className="ion-text-center">
                  <h3>NewPleの行動指針</h3>
                  <p>
                    私たちは成長を続け私たちの考えの遂行のために制作・開発を行う上で次の6つの指針を設けています。
                    これらはお客様とWebサイトを利用するお客様のために順守いたします。
                  </p>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>学び続けていこう。</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <p>
                        常に進化し続ける技術が有用であるものならば、私たちは新しいものを取り入れることを迷いません。
                      </p>
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>能動的に行動しよう。</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <p>
                        「こうなったらいいな」などと理想を言うことは簡単です。私たちは常に改善するためのアイデアを考えます。
                      </p>
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>謙虚にいよう。</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <p>
                        私たちは素直に他人の意見を聞き、何が最善か考えます。
                        他人から学ぶことは非常に大きな価値があると考えています。
                      </p>
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>報酬以上の価値を追究しよう。</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      {/* <p>
                            私たちは制作物に見合わない報酬を求めることは絶対にしません。常に満足してもらう方法を考えつくします。
                          </p> */}
                      <p>常にいただく報酬以上の価値を追求します。</p>
                    </IonCardContent>
                  </IonCard>
                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>誠実であろう。</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      {/* <p>
                            私たちは都合の良いことばかりを並べません。時には都合の悪いお話をするかもしれませんが、適切な情報をお伝えします。
                          </p> */}
                      <p>
                        私たちはお客様のため、Webサイトを訪れる方々の両方のために、NewPleにとって都合の悪い情報であろうと適切な情報をお伝えすることを約束します。
                      </p>
                    </IonCardContent>
                  </IonCard>

                  <IonCard>
                    <IonCardHeader>
                      <IonCardTitle>尽くす気持ちでいよう。</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <p>
                        仲間内だけでなく相手のことを一歩先まで考えます。貢献すると気分が良いだけではなく、大きなチャンスが巡ってくる。私たちはそう信じています。
                      </p>
                    </IonCardContent>
                  </IonCard>
                </IonCol>
              </IonRow>
            </IonGrid>
          </section>
        )}

        {lgUp ? <div className="ion-padding-vertical" /> : null}
        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}

        <section className="ion-padding" style={{ backgroundColor: "#ffebee" }}>
          <IonCard
            style={{ width: `${smUp ? "60%" : "100%"}`, margin: "0 auto" }}
          >
            <IonCardHeader>
              <IonCardTitle>
                <h3 className="ion-text-center">会社概要</h3>
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <div className="ion-padding">
                {companyInfo.map(({ label, content }, index) => {
                  return (
                    <IonItem key={index}>
                      {smUp ? (
                        <>
                          <IonLabel>{label}</IonLabel>
                          <p>{content.join(", ")}</p>
                        </>
                      ) : (
                        <>
                          <IonLabel
                            position="stacked"
                            className="ion-padding-bottom"
                          >
                            {label}
                          </IonLabel>
                          {content.map((item, index) => {
                            return <p key={index}>{item}</p>;
                          })}
                        </>
                      )}
                    </IonItem>
                  );
                })}
              </div>
              {/* <IonList>
                <IonItem lines="none">
                <IonLabel>社名</IonLabel>
                <p>合同会社NewPle</p>
                </IonItem>
                <IonItem lines="none">
                <IonLabel>設立</IonLabel>
                <p>2020年7月13日</p>
                </IonItem>
                <IonItem lines="none">
                <IonLabel>所在地</IonLabel>
                <p>東京都世田谷区</p>
                </IonItem>
                <IonItem lines="none">
                <IonLabel>事業内容</IonLabel>
                <IonText>
                <p>Web制作</p>
                <p>アプリ開発</p>
                </IonText>
                </IonItem>
                <IonItem lines="none">
                <IonLabel>代表取締役(2名)</IonLabel>
                <IonText>
                <p>新開崇弘</p>
                <p>高田楓我</p>
                </IonText>
                </IonItem>
              </IonList> */}
            </IonCardContent>
          </IonCard>
        </section>
        {lgUp ? (
          <div
            className="ion-padding-vertical"
            style={{ backgroundColor: "#ffebee" }}
          />
        ) : null}
      </IonContent>
    </IonPage>
  );
};

export default About;
